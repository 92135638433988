import React, { useRef, useEffect } from "react";
import "./sliderHeader.css";

const SliderHeader = ({ closeData, headerMessage }) => {
  const SendMessageRef = useRef();
  useEffect(() => {
    SendMessageRef.current.value = closeData.data[closeData.index].text;
    console.log("al text", closeData.data[closeData.index]);
  }, [closeData.index]);

  return (
    <div>
      <div className="slider-header">
        <button className="close" onClick={closeData.onClose}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="slider-compBtn2">
        <div className="message-box">
          <input
            className="message"
            ref={SendMessageRef}
            onChange={() => {
              closeData.reRender();
              closeData.data[closeData.index].text =
                SendMessageRef.current.value;
            }}
          />
          <p className="message-description">{headerMessage}</p>
        </div>
      </div>
    </div>
  );
};

export default SliderHeader;
