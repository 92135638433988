import SliderHeader from "../../Components/ui/sliderHeader/SliderHeader";
import React, { useState, useEffect } from "react";
import Reactquill from "../../Components/ui/reactquill/Reactquill";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TextFieldsOutlinedIcon from "@mui/icons-material/TextFieldsOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import FormatListNumberedOutlinedIcon from "@mui/icons-material/FormatListNumberedOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Box,
  Typography,
} from "@mui/material";
import DesignButton from "../../Components/ui/button/DesignButton";
import "../../styles/slider.css";
import DeleteButton from "../../Components/ui/button/DeleteButton";
import KeyboardBackspaceTwoToneIcon from "@mui/icons-material/KeyboardBackspaceTwoTone";
import IOSSwitch from "./IosSwitch";

const FormsModal = ({ allData }) => {
  const [allInputsTypes, setAllInputsTypes] = useState(
    allData?.data[allData.index]?.forms || []
  );

  const [showInputFields, setShowInputFields] = useState(false);
  const [newFieldName, setNewFieldName] = useState("");
  const [newFieldType, setNewFieldType] = useState("text");
  const [optionalField, setOptionalField] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [isNameValid, setIsNameValid] = useState(false);

  const [editorContent, setEditorContent] = useState([""]);
  useEffect(() => {
    try {
      editorContent[0] = allData.data[allData.index].description;
    } catch (e) {}
  }, [allData.index]);

  const toggleInputFields = () => {
    setShowInputFields(!showInputFields);
  };

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const addInputField = () => {
    if (newFieldName.trim() !== "") {
      setAllInputsTypes([
        ...allInputsTypes,
        { value: newFieldName, type: newFieldType, optional: optionalField },
      ]);
      setNewFieldName("");
      setNewFieldType("text");
      setOptionalField(false);
      toggleInputFields();
      allData.data[allData.index].forms.push({
        value: newFieldName,
        type: newFieldType,
        optional: optionalField,
      });
      closeDialog(); // Close the dialog after adding the field.
    }
  };

  const handleDeleteField = (index) => {
    const updatedForms = [...allData.data[allData.index].forms];
    updatedForms.splice(index, 1);
    allData.data[allData.index].forms = updatedForms;
    setAllInputsTypes(updatedForms);
  };

  const [selectedInput, setSelectedInput] = useState(null);

  const handleSelectInput = (input) => {
    setSelectedInput(input);
  };

  const handleSaveInput = (input, newName, newType, newRequierd) => {
    const updatedInputs = allInputsTypes.map((e) =>
      e === input
        ? { ...e, value: newName, type: newType, optional: newRequierd }
        : e
    );

    allData.data[allData.index].forms = updatedInputs;

    // Update the state and data
    setAllInputsTypes(updatedInputs);
    input.value = newName;
    input.type = newType;
    input.optional = newRequierd;
    setSelectedInput(null); // Clear the selection
  };

  const handleBackToMainPage = () => {
    setSelectedInput(null); // Clear the selection when going back to the main page
  };

  const handleNameChange = (e) => {
    const nameValue = e.target.value;
    setNewFieldName(nameValue);

    // Check if the name is valid (not empty or containing only symbols)
    const isValid = nameValue.trim() !== "" && /[a-zA-Z]/.test(nameValue);
    setIsNameValid(isValid);
  };

  return (
    <div className="slider" style={{ right: allData.isOpen ? "0" : "-350px" }}>
      {selectedInput ? (
        <InputDetailPage
          input={selectedInput}
          onSave={handleSaveInput}
          onBack={handleBackToMainPage}
        />
      ) : (
        <>
          <SliderHeader
            closeData={allData}
            headerMessage={
              "Quickens the collection of multiple inputs by providing a form"
            }
          ></SliderHeader>

          {editorContent.map((content, index2) => (
            <div className="slider-comp">
              <div className="disc">
                <p className="message-description">Send this message</p>
              </div>
              <Reactquill
                ediotrs={editorContent}
                cont={content}
                index={index2}
                Data={allData}
                placehold="Please fill in the following details"
              ></Reactquill>
            </div>
          ))}

          <div className="slider-compBtn">
            <Box className="message-container">
              <p className="message-description">Fields included in the form</p>
            </Box>

            <div className="fileds-container">
              {allInputsTypes.length !== 0 ? (
                allInputsTypes.map((e, index) => (
                  <Box className="input-types">
                    <Stack direction="row" justifyContent={"space-between"}>
                      <Button
                        onClick={() => handleSelectInput(e)}
                        style={{
                          flex: "2",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Box className="single-field">
                          <Box className="field-icon">
                            {e.type === "text" && <TextFieldsOutlinedIcon />}
                            {e.type === "email" && <EmailOutlinedIcon />}
                            {e.type === "tel" && <LocalPhoneOutlinedIcon />}
                            {e.type === "number" && (
                              <FormatListNumberedOutlinedIcon />
                            )}
                            {e.type === "date" && <CalendarMonthOutlinedIcon />}
                          </Box>
                          <Box className="field-name">{e.value}</Box>
                        </Box>
                      </Button>
                      <Box className="delete-field">
                        <DeleteOutlineOutlinedIcon
                          onClick={() => handleDeleteField(index)}
                        />
                      </Box>
                    </Stack>
                  </Box>
                ))
              ) : (
                <></>
              )}
            </div>
            <DesignButton
              style={{ marginBottom: "35px !important" }}
              func={openDialog}
              text="Form Field"
            ></DesignButton>
            <Dialog
              open={isDialogOpen}
              onClose={closeDialog}
              maxWidth="xs"
              fullWidth={"true"}
            >
              <DialogTitle className="dialog-title">Add Field</DialogTitle>
              <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                  <Stack spacing={4} marginTop={"10px"}>
                    <>
                      <TextField
                        label="Name"
                        variant="outlined"
                        value={newFieldName}
                        onChange={(e) => handleNameChange(e)}
                      />
                      <Typography
                        variant="body2"
                        color={isNameValid ? "textPrimary" : "error"}
                        style={{ margin: "10px 0 0 20px" }}
                      >
                        {isNameValid ? "Valid" : "Not Valid"}
                      </Typography>
                    </>
                    <FormControl variant="outlined">
                      <InputLabel id="field-type-label">Type</InputLabel>
                      <Select
                        labelId="field-type-label"
                        id="demo-simple-select"
                        value={newFieldType}
                        onChange={(e) => setNewFieldType(e.target.value)}
                        label="Type"
                        autoWidth
                      >
                        <MenuItem value="text">Text</MenuItem>
                        <MenuItem value="email">Email</MenuItem>
                        <MenuItem value="tel">Phone</MenuItem>
                        <MenuItem value="number">Number</MenuItem>
                        <MenuItem value="date">Date</MenuItem>
                      </Select>
                    </FormControl>
                    <Stack
                      flexDirection="row"
                      justifyContent="space-around"
                      alignItems="center"
                      style={{ marginTop: "20px" }}
                    >
                      <p>Make this input optional</p>

                      <IOSSwitch
                        checked={optionalField === true}
                        onChange={(e) =>
                          setOptionalField(e.target.checked ? true : false)
                        }
                      />
                    </Stack>
                  </Stack>

                  <DialogActions className="dialog-btns">
                    <DeleteButton
                      func={closeDialog}
                      text="close"
                    ></DeleteButton>
                    <DesignButton
                      func={addInputField}
                      text="add"
                    ></DesignButton>
                  </DialogActions>
                </form>
              </DialogContent>
            </Dialog>
          </div>
        </>
      )}
    </div>
  );
};

export default FormsModal;

const InputDetailPage = ({ input, onSave, onBack }) => {
  const [name, setName] = useState(input.value);
  const [type, setType] = useState(input.type);
  const [requierd, setRequierd] = useState(input.optional);
  const [isNameValid, setIsNameValid] = useState(false);

  // Update isNameValid based on the initial value of name
  useEffect(() => {
    const isValid = name.trim() !== "" && /[a-zA-Z]/.test(name);

    setIsNameValid(isValid);
  }, [name]);

  const handleNameChange = (e) => {
    const nameValue = e.target.value;
    setName(nameValue);
  };

  const handleSave = () => {
    onSave(input, name, type, requierd);
  };

  return (
    <div>
      <Stack flexDirection="row" justifyContent="flex-start">
        <Button onClick={onBack}>
          <KeyboardBackspaceTwoToneIcon></KeyboardBackspaceTwoToneIcon>
          <p style={{ marginLeft: "10px", textTransform: "capitalize" }}>
            Back to Form
          </p>
        </Button>
      </Stack>

      <Stack spacing={4} marginTop={"10px"}>
        <>
          <TextField
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => handleNameChange(e)}
          />
          <Typography
            variant="body2"
            color={isNameValid ? "textPrimary" : "error"}
            style={{ margin: "10px 0 0 20px" }}
          >
            {isNameValid ? "Valid" : "Not Valid"}
          </Typography>
        </>

        <FormControl variant="outlined">
          <InputLabel id="field-type-label">Type</InputLabel>
          <Select
            labelId="field-type-label"
            id="demo-simple-select"
            value={type}
            onChange={(e) => setType(e.target.value)}
            label="Type"
            autoWidth
          >
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="email">Email</MenuItem>
            <MenuItem value="tel">Phone</MenuItem>
            <MenuItem value="number">Number</MenuItem>
            <MenuItem value="date">Date</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Stack
        flexDirection="row"
        justifyContent="space-around"
        alignItems="center"
        style={{ marginTop: "20px" }}
      >
        <p>Make this input optional</p>

        <IOSSwitch
          checked={requierd === true}
          onChange={(e) => setRequierd(e.target.checked ? true : false)}
        />
      </Stack>
      <Stack
        justifyContent="flex-end"
        style={{ marginTop: "20px", width: "fit-content", marginLeft: "auto" }}
      >
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={!isNameValid}
        >
          Save
        </Button>
      </Stack>
    </div>
  );
};
