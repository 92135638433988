import React, { useState, useRef, useEffect } from "react";
import { htmlToText } from "html-to-text";
import "../styles/slider.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import validator from "validator";
import { format } from "date-fns";
import "react-day-picker/dist/style.css";
import ImageCaruselModal from "../features/slider/ImageCaruselModal";
import FormsModal from "../features/slider/FormsModal";
import CalenderModal from "../features/slider/CalenderModal";
import SendMessage from "../features/slider/SendMessage";
import ButtonsOptions from "../features/slider/ButtonsOptions";

export default function Slide(props) {
  const [message, setMessage] = useState();
  const [textArea, setTextArea] = useState("");

  const handleMessageChange = (e) => {
    const newValue = e.target.value;
    setMessage(newValue);
    props.handleMessage(newValue);
  };
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
    ],
    clipboard: {
      matchVisual: false, // Prevent converting spaces to new lines
    },
  };
  const formats = ["bold", "italic", "underline", "list", "link", "image"];
  const [editorContent, setEditorContent] = useState([""]);
  const handleEditorChange = (html) => {
    const text = htmlToText(html, {
      wordwrap: 130, // Adjust this to control line length
    });
    return text;
  };

  // Create a function to handle the input change

  // end image carousel

  // const handleFileUpload = (e) => {
  //   const files = e.target.files;
  //   const newSelectedFiles = Array.from(files);
  //   setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
  //   setFileSelected(true);
  //   console.log("5lsny", selectedFiles);

  //   if (newSelectedFiles) {
  //     const formData = new FormData();
  //     formData.append("image", newSelectedFiles);
  //     console.log("haaa?", formData);
  //   }
  // };

  // const handleCombinedButtonClick = () => {
  //   if (fileSelected) {
  //     const fileToUpload = selectedFiles[0];
  //     // Simulate an upload (you can replace this with actual upload logic)
  //     setTimeout(() => {
  //       console.log("Uploading:", fileToUpload.name);
  //       // Remove the uploaded file from the selected files and add it to the file queue
  //       setSelectedFiles(selectedFiles.slice(1));
  //       setFileQueue([...fileQueue, fileToUpload]);
  //       setFileSelected(false);
  //       console.log("tl3 b2a", fileQueue);
  //     }, 1000); // Simulating a delay for the upload
  //   } else {
  //     // If no files are selected, trigger the file input to allow users to select files
  //     fileInputRef.current.click();
  //   }
  // };





  const [drobDown, setDrobDown] = useState(false);
  const handleDrobDown = () => {
    setDrobDown(!drobDown);
  };
  const [send, setSend] = useState("");
  const [isSendValid, setIsSendValid] = useState(true);
  const [cc, setCC] = useState("");
  const [isCCValid, setIsCCValid] = useState(true);
  const [bcc, setBCC] = useState("");
  const [isBCCValid, setIsBCCValid] = useState(true);

  const handleSendChange = (e) => {
    const newEmail = e.target.value;
    setSend(newEmail);
    setIsSendValid(validator.isEmail(newEmail));
  };
  const handleCCChange = (e) => {
    const newEmail = e.target.value;
    setCC(newEmail);
    setIsCCValid(validator.isEmail(newEmail));
  };
  const handleBCCChange = (e) => {
    const newEmail = e.target.value;
    setBCC(newEmail);
    setIsBCCValid(validator.isEmail(newEmail));
  };

  let type;

  const SendMessageRef = useRef();

  useEffect(() => {
    try {
      SendMessageRef.current.value = props.data[props.index].text;
      editorContent[0] = props.data[props.index].description;
      setTextArea(props.data[props.index].description);
    } catch (e) {}
  }, [props.index]);

  if (props.isOpen) type = props.data[props.index].type;
  let Text = "";
  switch (type) {
    default:
      return null;
    case "Send message":
      return <SendMessage allData={props} />;
    case "Collect Input":
      return (
        <div
          className={"slider"}
          style={{ right: props.isOpen ? "0" : "-350px" }}
        >
          <div className="slider-header">
            <button className="close" onClick={props.onClose}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="slider-comp">
            <div className="message-box">
              <input
                className="message"
                ref={SendMessageRef}
                onChange={() => {
                  props.reRender();
                  props.data[props.index].text = SendMessageRef.current.value;
                }}
              />
              <p className="message-description">
                Send message will only send messages to the user and not require
                a response back
              </p>
            </div>
          </div>
          <div className="slider-comp">
            <p className="message-description">Send this message</p>
          </div>
          <div className="quill">
            <ReactQuill
              placeholder="what is your..."
              value={textArea}
              onChange={(content) => {
                setTextArea(content);

                props.data[props.index].description = handleEditorChange(
                  content
                );
              }}
              theme="snow"
              modules={modules}
              formats={formats}
            />
          </div>
          <div className="drop-down slider-comp">
            <p className="message-description">
              Save the response in this variable
            </p>
            <input
              className="text-box"
              type="text"
              readOnly
              placeholder="Select"
              onClick={handleDrobDown}
            />
            {drobDown ? (
              <div className="options">
                <div className="option">
                  <i className="fa-solid fa-user" />
                  <span>name</span>
                </div>
                <div className="option">
                  <i className="fa-solid fa-envelope" />
                  <span>email</span>
                </div>
                <div className="option">
                  <i className="fa-solid fa-phone" />
                  <span>phone</span>
                </div>
                <div className="option">
                  <i className="fa-solid fa-calendar" />
                  <span>date</span>
                </div>
              </div>
            ) : null}
          </div>
           
        </div>
      );
    case "Send an email":
      return (
        <div
          className={"slider"}
          style={{ right: props.isOpen ? "0" : "-350px" }}
        >
          <div className="slider-header">
            <button className="close" onClick={props.onClose}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="slider-comp">
            <div className="message-box">
              <input
                className="message"
                value={message}
                onChange={handleMessageChange}
              />
              <p className="message-description">
                Send message will only send messages to the user and not require
                a response back
              </p>
            </div>
          </div>
          <div className="send-email slider-comp">
            <div className="email-input">
              <label htmlFor="send" style={{ color: isSendValid ? "" : "red" }}>
                Send an email to
              </label>
              <input
                id="send"
                type="text"
                placeholder="name@example.com"
                value={send}
                onChange={handleSendChange}
                className={isSendValid ? "" : "invalid"}
                style={{ borderColor: isSendValid ? "" : "red" }}
              />
            </div>
            <div className="email-input">
              <label htmlFor="cc" style={{ color: isCCValid ? "" : "red" }}>
                CC
              </label>
              <input
                id="cc"
                name="cc"
                type="email"
                placeholder="name@example.com"
                value={cc}
                onChange={handleCCChange}
                className={isCCValid ? "" : "invalid"}
                style={{ borderColor: isCCValid ? "" : "red" }}
              />
            </div>
            <div className="email-input">
              <label htmlFor="cc" style={{ color: isBCCValid ? "" : "red" }}>
                BCC
              </label>
              <input
                id="bcc"
                name="bcc"
                type="email"
                placeholder="name@example.com"
                value={bcc}
                onChange={handleBCCChange}
                className={isBCCValid ? "" : "invalid"}
                style={{ borderColor: isBCCValid ? "" : "red" }}
              />
            </div>
            <div className="email-subject slider-comp">
              <label htmlFor="subject">Subject</label>
              <textarea id="subject"></textarea>
            </div>
            <div className="email-markup slider-comp">
              <ReactQuill
                style={{ height: "200px", marginBottom: "50px" }}
                placeholder="Add what you want to be notified when an email is sent..."
                theme="snow"
                modules={modules}
                formats={formats}
              />
            </div>
          </div>
        </div>
      );
    case "Calender":
      return <CalenderModal allData={props} />;
    case "Buttons":
      return <ButtonsOptions allData={props} />;
    case "Branch":
      return (
        <div
          className={"slider"}
          style={{ right: props.isOpen ? "0" : "-350px" }}
        >
          <div className="slider-header">
            <button className="close" onClick={props.onClose}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="slider-comp">
            <div className="message-box">
              <input
                className="message"
                ref={SendMessageRef}
                onChange={() => {
                  props.reRender();
                  props.data[props.index].text = SendMessageRef.current.value;
                }}
              />
              <p className="message-description">
                Send message will only send messages to the user and not require
                a response back
              </p>
            </div>
          </div>
          <div className="slider-comp">
            <p className="message-description">Send this message</p>
          </div>
          <div className="quill">
            <ReactQuill
              placeholder="what is your..."
              value={message}
              onChange={(content) => {
                setTextArea(content);

                props.data[props.index].description = handleEditorChange(
                  content
                );
              }}
              theme="snow"
              modules={modules}
              formats={formats}
            />
          </div>
          <button
            onClick={() => {
              props.addChild();
            }}
          ></button>
        </div>
      );
    case "Carousel":
      return (
        <div
          className={"slider"}
          style={{ right: props.isOpen ? "0" : "-350px" }}
        >
          <div className="slider-header">
            <button className="close" onClick={props.onClose}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="slider-comp">
            <div className="message-box">
              <input
                className="message"
                ref={SendMessageRef}
                onChange={() => {
                  props.reRender();
                  props.data[props.index].text = SendMessageRef.current.value;
                }}
              />
              <p className="message-description">
                Send message will only send messages to the user and not require
                a response back
              </p>
            </div>
          </div>
          <div className="slider-comp">
            <p className="message-description">Send this message</p>
          </div>
          <div className="quill">
            <ReactQuill
              placeholder="what is your..."
              value={message}
              onChange={(content) => {
                setTextArea(content);

                props.data[props.index].description = handleEditorChange(
                  content
                );
              }}
              theme="snow"
              modules={modules}
              formats={formats}
            />
          </div>
          <button
            onClick={() => {
              props.addChild();
            }}
          ></button>
        </div>
      );
    case "Image Carousel":
      return <ImageCaruselModal allData={props} />;
    case "Forms":
      return <FormsModal allData={props} />;
  }
}
