import React, { useState, useEffect } from "react";
import SliderHeader from "../../Components/ui/sliderHeader/SliderHeader";
import Reactquill from "../../Components/ui/reactquill/Reactquill";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DesignButton from "../../Components/ui/button/DesignButton";
import "../../styles/slider.css";

const ButtonsOptions = ({ allData }) => {
  const [editorContent, setEditorContent] = useState([""]);
  useEffect(() => {
    try {
      editorContent[0] = allData.data[allData.index].description;
    } catch (e) {}
  }, [allData.index]);
  return (
    <div className="slider" style={{ right: allData.isOpen ? "0" : "-350px" }}>
      <SliderHeader
        closeData={allData}
        headerMessage={
          "Send message will only send messages to the user and not require a response back"
        }
      ></SliderHeader>

      {editorContent.map((content, index2) => (
        <div className="slider-comp">
          <div className="disc">
            <p className="message-description">Send this message</p>
          </div>
          <Reactquill
            ediotrs={editorContent}
            cont={content}
            index={index2}
            Data={allData}
            placehold="What would you like to choose?"
          ></Reactquill>
        </div>
      ))}

      <div className="add-btn-list">
        {allData.data[allData.index].children.map((item, index) => (
          <div className="buttons-info">
            <textarea
              autoFocus
              rows="1"
              cols="50"
              value={allData.data[item].text}
              onChange={(e) => {
                allData.data[item].text = e.target.value;
                allData.reRender();
              }}
            ></textarea>
            {index !== 0 ? (
              <div className="delet-btn">
                <DeleteOutlinedIcon
                  className="delete-outlined-icon"
                  onClick={() => {
                    if (index > 0) {
                      allData.data[allData.index].children.splice(index, 1);
                      allData.data.splice(item, 1);
                      allData.pathData.splice(
                        allData.data[allData.index].childrenR[index]
                      );
                      allData.data[allData.index].childrenR.splice(index, 1);
                      allData.reRender();
                    }
                  }}
                />
              </div>
            ) : null}
          </div>
        ))}
        <DesignButton
          func={allData.addChild}
          text="Add button"
        ></DesignButton>
      </div>
    </div>
  );
};

export default ButtonsOptions;
