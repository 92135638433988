import React, { useEffect, useState } from "react";
import ChatBot from "react-simple-chatbot";
import SliderCards from "./Slider";
import DateInput from "./DateInput";
import logo from "../../assets/images/logo.jpg";
import ChatForm from "./ChatForm";

const InterviewChatbot = ({ sendDataToParent }) => {
  const [data, setData] = useState([]);
  console.log("data>>>", data && data);

  // to store the user inputs
  const [userResponses, setUserResponses] = useState([]);
  const handleUserInput = response => {
    // Store the user's response in the state
    setUserResponses(prevResponses => [...prevResponses, response]);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://chatbotapi.mygatein.com/chatbot/KBot/getid?iddata={"AccountNumber":80}`
        );
        const data = await response.json();
        console.log(data[0]?.jsonData, "hobee");
        setData(JSON.parse(data[0]?.jsonData));
      } catch (error) {
        console.error("Error fetching data:", error);
        // console.log("kol al data", data);
      }
    };
    fetchData();
  }, [data]);

  // const updatedTrigger = data.map((step) => {
  //   // Check if the step has a trigger property
  //   if (step.trigger) {
  //     // Add an onClick function to the step
  //     step.onClick = () => {
  //       // Define your custom logic here
  //       console.log(`Clicked on step with ID: ${step.id}`);
  //       // You can add more custom logic based on the step
  //     };
  //   }
  //   return step;
  // });

  // useEffect(() => {
  //    data[5]?.component === "LaserTreatment"
  // },[data])

  // const steps = [
  //   { id: "1", message: "Welcome", trigger: "T1" },
  //   {
  //     id: "T1",
  //     component: (
  //       <DateInput
  //         disabledDates={[
  //           "2023-10-27T22:00:00.000Z",
  //           "2023-10-28T22:00:00.000Z",
  //         ]}
  //         disabledDays={[5]}
  //       />
  //     ),
  //     waitAction: true,
  //     trigger: "nextStepAfterDateInput",
  //   },
  //   {
  //     id: "nextStepAfterDateInput",
  //     message: ({ previousValue }) => `${previousValue}`,
  //   },
  // ];

  // const steps = [
  //   { id: "1", message: "Welcome", trigger: "T1" },
  //   {
  //     id: "T1",
  //     component: "DateInput",
  //     disabledDates: ["2023-10-27T22:00:00.000Z", "2023-10-28T22:00:00.000Z"],
  //     disabledDays: [5],
  //     waitAction: true,
  //     trigger: "nextStepAfterDateInput",
  //     triggerNextStep: true, // Pass the triggerNextStep prop
  //   },
  //   {
  //     id: "nextStepAfterDateInput",
  //     message: ({ previousValue }) => `${previousValue}`,
  //   },
  // ];

  // console.log("al steps", steps);

  const updatedSteps = data.map((step, index) => {
    const updatedStep = { ...step };
    if (updatedStep.component === "DateInput") {
      // Set the component property for image steps
      const modifiedStep = {
        id: updatedStep.id,
        trigger: updatedStep.trigger,
        component: updatedStep.component
        // waitAction: updatedStep.waitAction,
      };

      modifiedStep.component = (
        <div style={{ backgroundColor: "#e7eaec" }}>
          <DateInput
            disabledDates={updatedStep.disabledDates}
            disabledDays={updatedStep.disabledDays}
            onInputSubmit={handleUserInput}
          />
        </div>
      );
      return modifiedStep;
    } else if (updatedStep.component === "SliderCards") {
      // Set the component property for image steps
      const modifiedStep = {
        id: updatedStep.id,
        trigger: updatedStep.trigger,
        component: updatedStep.component
      };

      modifiedStep.component = (
        <div style={{ backgroundColor: "#e7eaec" }}>
          <SliderCards theURL={updatedStep.SliderCards} />
        </div>
      );
      return modifiedStep;
    } else if (updatedStep.component === "ChatForm") {
      // Set the component property for image steps
      const modifiedStep = {
        id: updatedStep.id,
        trigger: updatedStep.trigger,
        component: updatedStep.component
      };

      modifiedStep.component = (
        <div style={{ backgroundColor: "#e7eaec" }}>
          <ChatForm
            allForms={updatedStep.ChatForm}
            onInputSubmit={handleUserInput}
          />
        </div>
      );
      return modifiedStep;
    }
    console.log("b3d al step", updatedStep);

    return updatedStep;
  });

  console.log("kol al updates", updatedSteps);

  useEffect(() => {
    // console.log("al response", userResponses);
    // const fetchData = async () => {
    //   try {
    //     await fetch(
    //       `https://chatbotapi.mygatein.com/chatbot/KBot/FN2?iddata=${JSON.stringify(
    //         userResponses
    //       )}`
    //     );
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // };
    // fetchData();
    sendDataToParent(userResponses);
  }, [userResponses]);

  return data.length > 0 ? (
    <ChatBot steps={updatedSteps} botAvatar={logo} headerTitle="Niyat-نياط" />
  ) : (
    <div style={{ color: "white" }}>Loading... </div>
  );
};

export default InterviewChatbot;
