import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom"; // Import necessary components and functions

import "./styles/all.min.css";
import Login from "./features/auth/pages/Login.jsx";
import App from "./App";
import InterviewChatbot from "./features/viewer/pages/InterviewChatbot.jsx";
// import { ChakraProvider } from "@chakra-ui/react";

const Root = document.getElementById("root");

ReactDOM.createRoot(Root).render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login />} />{" "}
      <Route path="/designer" element={<App />} />{" "}
      <Route path="/InterviewChatbot" element={<InterviewChatbot />} />{" "}
    </Routes>
  </BrowserRouter>
);
