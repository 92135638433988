import React, { useState, useRef } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SliderHeader from "../../Components/ui/sliderHeader/SliderHeader";
import { Box, Stack } from "@mui/material";
import "../../styles/slider.css";
import DeleteButton from "../../Components/ui/button/DeleteButton";

const ImageCaruselModal = ({ allData }) => {
  const [textInputs, setTextInputs] = useState([]);
  const [urlInputs, setUrlInputs] = useState([]);
  const [urlInputErrors, setUrlInputErrors] = useState([]);
  const [isURLValidate, setIsURLValid] = useState([]); // State to track URL validation

  const fileInputRef = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const handleUplaodImage = (e) => {
    // taking the image data and store it in the file
    const file = e.target.files[0];

    // console.log("al image", JSON.stringify(file));

    // handle the formData to path it to the fetch
    const formData = new FormData();
    formData.append("formFile", file);
    formData.append("fileName", file.name);

    setIsLoading(true);

    fetch("https://chatbotapi.mygatein.com/file", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text(); // Parse the response as plain text
      })
      .then((data) => {
        // console.log("all data", data);

        const parts = data.split("wwwroot\\");
        const extractedString = parts[1];
        const fullPath = "https://chatbotapi.mygatein.com/" + extractedString;

        // console.log("all files", file);
        // console.log("al images", allImages);

        const fullImageData = { path: fullPath, text: "", files: file };
        allData.data[allData.index].imageCarousel.push(fullImageData);
        allData.reRender();

        // Add an empty text input for the new image
        setTextInputs([...textInputs, ""]);

        // Add an empty url input for the new image
        setUrlInputs([...urlInputs, ""]);

        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const isURLValid = (url) => {
    const urlPattern = /^https?:\/\/.+/;
    return urlPattern.test(url);
  };

  const handleUrlInputChange = (e, index) => {
    const newURLInput = e.target.value;
    const updatedURLInputs = [...urlInputs];
    updatedURLInputs[index] = newURLInput;
    setUrlInputs(updatedURLInputs);

    // Only validate the URL and set the error message if it's non-empty
    let isValid = true;
    if (newURLInput.trim() !== "") {
      isValid = isURLValid(newURLInput);
    }
    const updatedUrlInputErrors = [...urlInputErrors];
    updatedUrlInputErrors[index] = isValid ? "Valid URL" : "Invalid URL";
    setUrlInputErrors(updatedUrlInputErrors);

    allData.data[allData.index].imageCarousel[index].URL = newURLInput;

    // Update URL validation status for the specific URL input, excluding empty URLs
    const updatedIsURLValid = [...isURLValidate];
    updatedIsURLValid[index] = isValid;
    setIsURLValid(updatedIsURLValid);
  };

  const handleInputChange = (e, index) => {
    const newInput = e.target.value;
    const updatedTextInputs = [...textInputs];
    updatedTextInputs[index] = newInput;
    setTextInputs(updatedTextInputs);
    allData.data[allData.index].imageCarousel[index].text = newInput;
  };

  const handleDeleteImage = (index) => {
    const updatedImageCarousel = [...allData.data[allData.index].imageCarousel];
    updatedImageCarousel.splice(index, 1); // Remove the image at the specified index
    allData.data[allData.index].imageCarousel = updatedImageCarousel;
    allData.reRender();
  };

  return (
    <div
      className={"slider"}
      style={{ right: allData.isOpen ? "0" : "-350px" }}
    >
      <SliderHeader
        closeData={allData}
        headerMessage={
          "Displays one or more images in a carousel to make it easier for users to browse through"
        }
      ></SliderHeader>

      <div className="slider-compBtn">
        <Box className="message-container">
          <p className="message-description">
            Upload the images to be shown here
          </p>
        </Box>

        {isLoading ? (
          <BeatLoader
            className="spinner"
            color="#36d7b7"
            loading={isLoading}
            size={10}
          />
        ) : allData?.data &&
          allData?.data[allData.index]?.imageCarousel?.length !== 0 ? (
          allData?.data[allData.index]?.imageCarousel?.map((e, index) => (
            <ul key={index} className="img-informations">
              <li>
                <div className="image-container">
                  <img
                    src={URL.createObjectURL(e.files)}
                    width={100}
                    height={100}
                    alt=""
                  />
                  <p className="img-text">{e.files.name}</p>
                </div>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  className="input-container"
                >
                  <TextField
                    id="standard-basic"
                    label="Image Caption"
                    type="text"
                    variant="outlined"
                    value={e.text}
                    onChange={(e) => handleInputChange(e, index)}
                  />

                  <TextField
                    id="standard-basic"
                    style={{marginTop:"20px"}}
                    label="URL"
                    type="url"
                    variant="outlined"
                    value={e.URL}
                    onChange={(e) => handleUrlInputChange(e, index)}
                    error={urlInputErrors[index] !== ""}
                    helperText={urlInputErrors[index]}
                  />

                  <DeleteButton
                    func={() => handleDeleteImage(index)}
                    text="Remove"
                  ></DeleteButton>
                </Stack>
              </li>
            </ul>
          ))
        ) : (
          <></>
        )}
      </div>

      <div className="image-btn-container">
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          className="upload-img"
        >
          Upload an Image
          <VisuallyHiddenInput
            type="file"
            onChange={handleUplaodImage}
            ref={fileInputRef}
          />
        </Button>
      </div>
    </div>
  );
};

export default ImageCaruselModal;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
