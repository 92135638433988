import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Container, Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

import Logo from "../../assets/images/logo.jpg";

import EnglandFlag from "../../assets/united-kingdom.png";
import SaudiFlag from "../../assets/saudi-arabia.png";
const Header = ({ direction, setDirection }) => {
  const lang = localStorage.getItem("lang");
  const [language, setLanguage] = useState(lang);
  const { t, i18n } = useTranslation();

  const handleSelect = (e) => {
    setLanguage(e.target.value);
    setDirection(e.target.value);
  };

  return (
    <Box sx={{ flexGrow: 1, marginBottom: "4rem" }}>
      <AppBar
        position="fixed"
        style={{
          backgroundColor: "white",
          direction: `${lang === "ar" ? "rtl" : "ltr"}`,
        }}
      >
        <Container>
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Select
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              variant="standard"
              disableUnderline
              value={language}
              onChange={handleSelect}
            >
              <MenuItem value="en">
                <img
                  src={EnglandFlag}
                  style={{ width: "20px", paddingRight: "6px" }}
                  alt="England Flag"
                />
                English
              </MenuItem>

              <MenuItem value="ar">
                <img
                  src={SaudiFlag}
                  style={{ width: "20px", paddingRight: "6px" }}
                  alt="England Flag"
                />
                العربيه
              </MenuItem>
            </Select>
            <Box style={{ width: "60px", height: "60px" }}>
              <img
                style={{ width: "100%", height: "100%" }}
                src={Logo}
                alt="logo"
              />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

export default Header;
