import React from "react";
import ReactQuill from "react-quill";
import { htmlToText } from "html-to-text";


const Reactquill = ({ ediotrs, cont, index, Data , placehold }) => {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
    ],
    clipboard: {
      matchVisual: false, // Prevent converting spaces to new lines
    },
  };
  const handleEditorChange = (html) => {
    const text = htmlToText(html, {
      wordwrap: 130, // Adjust this to control line length
    });
    return text;
  };
  const formats = ["bold", "italic", "underline", "list", "link", "image"];
  return (
    <div className="quill">
      <ReactQuill
        key={index}
        placeholder={placehold}
        theme="snow"
        value={cont}
        onChange={(content) => {
          ediotrs[index] = content;
          Data.data[Data.index].description = handleEditorChange(ediotrs[0]);
        }}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default Reactquill;
