import React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import "./designButton.css";


const DesignButton = ({ func, text }) => {
  return (
    <Button
      variant="contained"
      onClick={func}
      className="form-field"
      startIcon={<AddIcon />}
    >
      {text}
    </Button>
  );
};

export default DesignButton;
