import React, { useState, useEffect, useRef } from "react";
import SliderHeader from "../../Components/ui/sliderHeader/SliderHeader";
import DesignButton from "../../Components/ui/button/DesignButton";
import ReactQuill from "react-quill";
import { htmlToText } from "html-to-text";
import BeatLoader from "react-spinners/BeatLoader";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import "../../styles/slider.css";
import powerPoint from "../../assets/filesTypes/powerPoint.png";
import excel from "../../assets/filesTypes/excel.png";
import word from "../../assets/filesTypes/word.png";
import pdf from "../../assets/filesTypes/pdf.png";
import video from "../../assets/filesTypes/video.png";
import file from "../../assets/filesTypes/file.png";
import audio from "../../assets/filesTypes/sound.png";
import DeleteButton from "../../Components/ui/button/DeleteButton";

const SendMessage = ({ allData }) => {
  const SendMessageRef = useRef();

  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
    ],
    clipboard: {
      matchVisual: false, // Prevent converting spaces to new lines
    },
  };
  const handleEditorChange = (html) => {
    const text = htmlToText(html, {
      wordwrap: 130, // Adjust this to control line length
    });
    return text;
  };

  // const convertEditorContentToText = (editorContent) => {
  //   return editorContent.map((content) => handleEditorChange(content));
  // };

  const formats = ["bold", "italic", "underline", "list", "link", "image"];

  const [editorContent, setEditorContent] = useState([""]);

  useEffect(() => {
    if (allData.data && allData.data[allData.index]) {
      if (
        Array.isArray(allData.data[allData.index].messageTexts) &&
        allData.data[allData.index].messageTexts.length > 0
      ) {
        // If messageTexts is not empty, set editorContent to the messageTexts data
        setEditorContent([...allData.data[allData.index].messageTexts]);
      } else {
        // If messageTexts is empty, set editorContent to the description
        setEditorContent([allData.data[allData.index].description]);
      }
    }
  }, [allData.data, allData.index]);

  const addEditor = () => setEditorContent([...editorContent, "value"]);

  const deleteEditor = (index) => {
    const newContent = [...editorContent];
    if (index > 0) {
      newContent.splice(index, 1);
      setEditorContent(newContent);
    }
  };

  // file upload
  const [textInputs, setTextInputs] = useState([]);
  const fileInputRef = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const handleUplaodImage = (e) => {
    // taking the image data and store it in the file
    const file = e.target.files[0];

    // console.log("al image", JSON.stringify(file));

    // handle the formData to path it to the fetch
    const formData = new FormData();
    formData.append("formFile", file);
    formData.append("fileName", file.name);

    setIsLoading(true);

    fetch("https://chatbotapi.mygatein.com/file", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text(); // Parse the response as plain text
      })
      .then((data) => {
        // console.log("all data", data);

        const parts = data.split("wwwroot\\");
        const extractedString = parts[1];
        const fullPath = "https://chatbotapi.mygatein.com/" + extractedString;

        // console.log("all files", file);
        // console.log("al images", allImages);

        const fullImageData = { path: fullPath, text: "", files: file };
        allData.data[allData.index].imageCarousel.push(fullImageData);
        allData.reRender();

        // Add an empty text input for the new image
        setTextInputs([...textInputs, ""]);

        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleInputChange = (e, index) => {
    const newInput = e.target.value;
    const updatedTextInputs = [...textInputs];
    updatedTextInputs[index] = newInput;
    setTextInputs(updatedTextInputs);
    allData.data[allData.index].imageCarousel[index].text = newInput;
  };

  const handleDeleteImage = (index) => {
    const updatedImageCarousel = [...allData.data[allData.index].imageCarousel];
    updatedImageCarousel.splice(index, 1); // Remove the image at the specified index
    allData.data[allData.index].imageCarousel = updatedImageCarousel;
    allData.reRender();
  };

  // Helper function to render the appropriate component based on file type
  const renderFile = (e) => {
    const fileType = e.files.type;

    if (fileType.startsWith("image")) {
      return (
        <img
          src={URL.createObjectURL(e.files)}
          width={100}
          height={100}
          alt=""
        />
      );
    } else if (fileType === "application/pdf") {
      return <img src={pdf} width={100} height={100} alt="pdf" />;
    } else if (
      fileType === "application/msword" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return <img src={word} width={100} height={100} alt="word" />;
    } else if (
      fileType === "application/vnd.ms-excel" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return <img src={excel} width={100} height={100} alt="excel" />;
    } else if (
      fileType === "application/vnd.ms-powerpoint" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      return (
        <img src={powerPoint} width={100} height={100} alt="power point" />
      );
    } else if (fileType.startsWith("video")) {
      return <img src={video} width={100} height={100} alt="video" />;
    } else if (fileType.startsWith("audio")) {
      return <img src={audio} width={100} height={100} alt="power point" />;
    } else {
      return <img src={file} width={100} height={100} alt="file" />;
    }
  };

  return (
    <div className="slider" style={{ right: allData.isOpen ? "0" : "-350px" }}>
      <SliderHeader
        closeData={allData}
        headerMessage={
          "Send message will only send messages to the user and not require a response back"
        }
      ></SliderHeader>

      {editorContent?.map((content, index2) => (
        <div className="slider-comp">
          <div
            className="msg-desc"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <p className="message-description">Send this message</p>
            <button
              className="delet-btn"
              onClick={() => deleteEditor(index2)}
              style={{
                backgroundColor: "transparent",
                border: "none",
                cursor: index2 === 0 ? "auto" : "pointer",
              }}
            >
              <i
                className="fa-regular fa-trash-can"
                style={{ color: index2 === 0 ? "transparent" : "gray" }}
              ></i>
            </button>
          </div>
          <ReactQuill
            key={index2}
            placeholder="what is the ...."
            theme="snow"
            value={
              index2 === 0 &&
              allData.data[allData.index].messageTexts.length !== 0
                ? SendMessageRef?.current?.value
                : content
            }
            ref={index2 === 0 ? SendMessageRef : null}
            onChange={(content) => {
              const updatedEditorContent = [...editorContent];
              updatedEditorContent[index2] = content;

              // Use the convertEditorContentToText function to convert the entire array to text
              // const textContentArray = convertEditorContentToText(
              //   updatedEditorContent
              // );

              // Use the handleEditorChange function to convert HTML to plain text
              const textContent = handleEditorChange(content);

              // Update the data in allData with the updated message text
              if (allData.data && allData.data[allData.index]) {
                allData.reRender();
                allData.data[allData.index].messageTexts = updatedEditorContent;
              }

              setEditorContent(updatedEditorContent);

              if (index2 === 0) {
                allData.reRender();
                allData.data[allData.index].description = textContent;
                // or
                // allData.data[allData.index].description = textContentArray[0];
              }
            }}
            modules={modules}
            formats={formats}
          />
        </div>
      ))}
      {isLoading ? (
        <BeatLoader
          className="spinner"
          color="#36d7b7"
          loading={isLoading}
          size={10}
        />
      ) : allData?.data &&
        allData?.data[allData.index]?.imageCarousel?.length !== 0 ? (
        allData?.data[allData.index]?.imageCarousel?.map((e, index) => (
          <ul key={index} className="img-informations">
            <li>
              <div
                className="image-container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>{renderFile(e)}</div>

                <p className="img-text">{e.files.name}</p>
              </div>
              <Stack
                alignItems="center"
                justifyContent="center"
                className="input-container"
              >
                <TextField
                  id="standard-basic"
                  label="Image Caption"
                  type="text"
                  variant="outlined"
                  value={e.text}
                  onChange={(e) => handleInputChange(e, index)}
                />
                <DeleteButton
                  func={()=>handleDeleteImage(index)}
                  text="Remove"
                ></DeleteButton>
              </Stack>
            </li>
          </ul>
        ))
      ) : (
        <></>
      )}

      <div className="adding-btns">
        <DesignButton func={addEditor} text="Text"></DesignButton>
        <div className="image-btn-container">
          <Button
            component="label"
            variant="contained"
            startIcon={<AddIcon />}
            className="upload-img"
            style={{marginTop:"20px"}}
          >
            file
            <VisuallyHiddenInput
              type="file"
              onChange={handleUplaodImage}
              ref={fileInputRef}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SendMessage;
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
