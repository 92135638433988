import React, { useState, useEffect, useRef } from "react";
import SliderHeader from "../../Components/ui/sliderHeader/SliderHeader";
import { DayPicker } from "react-day-picker";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
// import CloseIcon from "@mui/icons-material/Close";
import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Chip,
  Box,
  OutlinedInput,
} from "@mui/material";
import "../../styles/slider.css";

// styling the select material ui
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CalenderModal = ({ allData }) => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const [selectedDays, setSelectedDays] = useState([]); // the selected excluded days
  const [excludedDaysOfWeek, setExcludedDaysOfWeek] = useState([]); // Maintain an array of selected excluded days

  // const handleRemoveDay = (day) => {
  //   const updatedSelectedDays = selectedDays.filter(
  //     (selectedDay) => selectedDay !== day
  //   );
  //   handleSelectedDaysChange({ target: { value: updatedSelectedDays } });
  // };

  const handleSelectedDaysChange = (event) => {
    const selected = event.target.value;
    setSelectedDays(selected);
  };

  const SendMessageRef = useRef();

  useEffect(() => {
    SendMessageRef.current.value = allData.data[allData.index].description;
  }, [allData.index]);

  const handleExcludedDayChange = (day, checked) => {
    if (checked) {
      // Include the day in excludedDaysOfWeek
      setExcludedDaysOfWeek([...excludedDaysOfWeek, day]);
      allData.data[allData.index].disabledDays.push(day);
    } else {
      // Remove the day from excludedDaysOfWeek
      const updatedExcludedDaysOfWeek = excludedDaysOfWeek.filter(
        (excludedDay) => excludedDay !== day
      );
      setExcludedDaysOfWeek(updatedExcludedDaysOfWeek);
      const dayIndex = allData.data[allData.index].disabledDays.indexOf(day);
      if (dayIndex !== -1) {
        allData.data[allData.index].disabledDays.splice(dayIndex, 1);
      }
    }
    allData.reRender();
  };

  const [dateType, setDateType] = useState("selection");
  const handleDateType = (e) => {
    const type = e.target.id;
    setDateType(type);
  };

  const [dateDrop, setDateDrop] = useState(false);

  const handleDateDrob = () => {
    setDateDrop(!dateDrop);
  };

  const [selectedDates, setSelectedDates] = useState([]); // Maintain an array of selected dates
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set the time to midnight

  // Custom disabled function to prevent selecting dates before today
  const isBeforeToday = (date) => date < today;

  const handleDateSelect = (date) => {
    if (isBeforeToday(date) || excludedDaysOfWeek.includes(date.getDay())) {
      // Date is before today or is one of the excluded days, prevent selection
      return;
    }

    // Toggle selection of date
    const index = selectedDates.findIndex(
      (selectedDate) => selectedDate.getTime() === date.getTime()
    );

    if (index === -1) {
      // Date is not in the selectedDates array, add it
      setSelectedDates([...selectedDates, date]);
    } else {
      // Date is already in the selectedDates array, remove it
      const updatedDates = selectedDates.filter(
        (selectedDate, i) => i !== index
      );
      setSelectedDates(updatedDates);
    }
    // console.log("allSelectedDates", JSON.stringify(selectedDates));
    allData.data[allData.index].disabledDates.push(date);
    allData.reRender();
  };

  return (
    <div className="slider" style={{ right: allData.isOpen ? "0" : "-350px" }}>
      <SliderHeader
        closeData={allData}
        headerMessage={
          "Opens a calendar UI in the chat interface through which the user can select their desired dater"
        }
      ></SliderHeader>

      <div className="slider-comp">
        <div className="message-box">
          <div className="date-selection slider-comp">
            <button
              id="selection"
              onClick={handleDateType}
              style={{
                color: dateType === "selection" ? "#7D7D7D" : "#333333",
                borderBottom:
                  dateType === "selection" ? "3px solid #7D7D7D" : "none",
              }}
            >
              Date selection
            </button>
            <button
              id="exclude"
              onClick={handleDateType}
              style={{
                color: dateType === "exclude" ? "#7D7D7D" : "#333333",
                borderBottom:
                  dateType === "exclude" ? "3px solid #7D7D7D" : "none",
              }}
            >
              Exclude dates
            </button>
          </div>
          {dateType === "selection" ? (
            <>
              <div className="email-subject slider-comp">
                <label htmlFor="subject">Show message</label>
                <textarea
                  id="subject"
                  ref={SendMessageRef}
                  onChange={() => {
                    allData.reRender();
                    allData.data[allData.index].description =
                      SendMessageRef.current.value;
                  }}
                ></textarea>
                {/* <span className="under-input">
                  you can reference a variable by typing #
                </span> */}
              </div>
              {/* <div className="drop-down slider-comp">
                <span className="upove-input">
                  Save the response in this variable
                </span>
                <input
                  className="text-box"
                  type="text"
                  readOnly
                  placeholder="Select"
                  onClick={handleDrobDown}
                />
                <span className="under-input">
                  you can select a variable that can be referenced later in the
                  conversation
                </span>
                {drobDown ? (
                  <div className="options">
                    <div className="option">
                      <i className="fa-solid fa-user" />
                      <span>name</span>
                    </div>
                    <div className="option">
                      <i className="fa-solid fa-envelope" />
                      <span>email</span>
                    </div>
                    <div className="option">
                      <i className="fa-solid fa-phone" />
                      <span>phone</span>
                    </div>
                    <div className="option">
                      <i className="fa-solid fa-calendar" />
                      <span>date</span>
                    </div>
                  </div>
                ) : null}
              </div> */}
            </>
          ) : (
            <>
              <div className="drop-down slider-comp">
                <FormControl>
                  <label>Exclude All-Days</label>
                  <Select
                    displayEmpty
                    multiple
                    variant="outlined"
                    value={selectedDays}
                    onChange={handleSelectedDaysChange}
                    input={
                      <OutlinedInput id="select-multiple-chip" label="Chip" />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected &&
                          selected?.map((value) => (
                            <Chip
                              key={value}
                              label={`${daysOfWeek[value]}`}
                              // onDelete={() => handleRemoveDay(value)}
                              // deleteIcon={<CloseIcon />}
                            />
                          ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {daysOfWeek?.map((day, index) => (
                      <MenuItem
                        key={index}
                        value={index}
                        onClick={() =>
                          handleExcludedDayChange(
                            index,
                            !excludedDaysOfWeek.includes(index)
                          )
                        }
                      >
                        <div>
                          <Checkbox
                            checked={excludedDaysOfWeek.includes(index)}
                          />
                          {day}
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="exclude-date slider-comp">
                <label>Exclude specific dates</label>
                <div className="dates-container">
                  <i
                    className="fa-solid fa-calendar"
                    onClick={handleDateDrob}
                  />
                  <div className="text-box">
                    {selectedDates.map((date, index) => (
                      <div className="all-excluded-days" key={index}>
                        {date.toLocaleDateString()}
                        <HighlightOffOutlinedIcon
                          onClick={() => handleDateDelete(date)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                {dateDrop ? (
                  <DayPicker
                    mode="multiple" // Enable multiple date selection
                    selected={selectedDates}
                    onDayClick={handleDateSelect} // Use onDayClick for selecting dates
                    disabled={(date) =>
                      isBeforeToday(date) ||
                      excludedDaysOfWeek.includes(date.getDay())
                    } // Use the custom disabled function and excludedDaysOfWeek
                  />
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
  function handleDateDelete(date) {
    const updatedDates = selectedDates.filter(
      (selectedDate) => selectedDate.getTime() !== date.getTime()
    );
    setSelectedDates(updatedDates);
  }
};

export default CalenderModal;
