import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MediaCard from "./card";
import "../Styles/chat.css";
const SliderCards = ({ theURL }) => {
  console.log("al url", theURL);
  var settings = {
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div style={{ width: "250px", height: "100%" }} className="all-sliders">
      <Slider {...settings} className="slide">
        {theURL?.map((el, index) => (
          
            <MediaCard
              img={el.path && el.path}
              title={el.text}
              counting={index}
              url={el.URL}
            />
          
        ))}
      </Slider>
    </div>
  );
};

export default SliderCards;
