import React, { useState } from "react";
import classes from "./style/chatbot.module.css";

function ChatForm({ allForms, onInputSubmit }) {
  // Add a state variable to store user inputs
  const [userInputs, setUserInputs] = useState(Array(allForms.length).fill(""));
  console.log("all forms", allForms);

  const handleInputChange = (index, val, elValue) => {
    // Create an object with a 'text' property
    const updatedUserInput = { text: elValue, value: val };

    // Update the userInputs array at the specified index
    const updatedUserInputs = [...userInputs];
    updatedUserInputs[index] = updatedUserInput;

    // Set the updated array in the state
    setUserInputs(updatedUserInputs);
  };

  // Event handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Pass the user inputs back to the parent component
    onInputSubmit(userInputs);
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        {allForms?.map((el, index) => (
          <div className={classes.divchatbot}>
            <input
              className={classes.inputchatbot}
              type={el.type}
              name=""
              placeholder={el.value}
              onChange={(e) =>
                handleInputChange(index, e.target.value, el.value)
              }
              required={el.optional === "true"}
            />
          </div>
        ))}
        <div className={classes.divchatbotsubmit}>
          <button className={classes.submitchatbot}>Submit</button>
        </div>
      </form>
    </div>
  );
}

export default ChatForm;
