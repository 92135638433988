import { useEffect, useState } from "react";
import React from "react";

export default function Relation({ index, pathData, reRender, mousePos }) {
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const [check, setCheck] = useState(false);

  function Del() {
    if (check) {
      return (
        <foreignObject
          x={pos.x - 5}
          y={pos.y - 5}
          width="25"
          height="25"
          onMouseOut={() => {
            setCheck(false);
          }}
        >
          <div
            onClick={() => {
              pathData.splice(index, 1);
              reRender();

              setCheck(false);
            }}
            className="deleteR"
          ></div>
        </foreignObject>
      );
    }
  }
  try {
    return (
      <g>
        <path
          className="relation"
          stroke={check ? "blue" : "black"}
          strokeWidth={3}
          fill="black"
          d={`M ${pathData[index].x1} ${pathData[index].y1} L ${pathData[index].x2} ${pathData[index].y2}`}
        ></path>
        <Del></Del>
      </g>
    );
  } catch (e) {}
}
