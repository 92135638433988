// // import { useForm } from "react-hook-form"
// // import { Box, Button, Checkbox, Container, Flex, Heading, Image, SimpleGrid, Stack, Link as CLink, Text } from "@chakra-ui/react"
// // import Input from "../../../Components/form/Input";
// // import logo from "../../../assets/images/logo.png";
// // import authImg from "../../../assets/images/auth-img.jpeg";
// // import { useNavigate } from "react-router-dom";

// // const Login = () => {

// //   const {
// //     handleSubmit,
// //     formState: { errors },
// //     control
// //   } = useForm();

// //   const loginValidationSchema = {
// //     email: {
// //       required: ("validation.required")
// //     },
// //     password: {
// //       required: ("validation.required")
// //     }
// //   }

// //   const navigate = useNavigate()

// //   return (
// //     <Flex height="100vh" justifyContent="center" alignItems="center">
// //       <Container maxW="container.lg">
// //         <SimpleGrid
// //           columns={{ base: 1, md: 2 }}
// //           bg="light" boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
// //           paddingInline={8} paddingBlock={14} borderRadius={6}
// //         >
// //           <Box
// //             flex="1" display={{ base: "none", md: "block" }}
// //           >
// //             <Image
// //               src={logo}
// //               alt="brand"
// //               width="120px"
// //             />
// //             <Image
// //               src={authImg}
// //               alt="brand"
// //               height="100%"
// //               borderRadius={6}
// //               objectFit="contain"
// //             />
// //           </Box>
// //           <Box flex="1" paddingInline={{ base: 2, md: 8 }} >
// //             <form onSubmit={handleSubmit(values => {
// //               navigate("/designer")
// //             console.log("navigate")
// //             })}>
// //               <Heading
// //                 as="h2" color="dark" fontSize="1.5rem"
// //                 textTransform="capitalize" mb={2}
// //               >
// //                 login now,
// //               </Heading>
// //               <Text
// //                 mb={6} color="text" _firstLetter={{ textTransform: "capitalize" }}
// //                 fontSize=".9rem"
// //               >
// //                 login with your data and ensure that you account is correct.
// //               </Text>

// //               <Stack spacing={4}>
// //                 <Input
// //                   type="text"
// //                   name="email"
// //                   label="email"
// //                   isRequired
// //                   labelStyle={{ textTransform: "capitalize" }}
// //                   placeholder="email@example.com"
// //                   isFormController
// //                   control={control}
// //                   rules={loginValidationSchema.email}
// //                   error={errors.email}
// //                 />
// //                 <Input
// //                   type="password"
// //                   name="password"
// //                   label="password"
// //                   isRequired
// //                   labelStyle={{ textTransform: "capitalize" }}
// //                   placeholder="password"
// //                   isFormController
// //                   control={control}
// //                   rules={loginValidationSchema.password}
// //                   error={errors.password}
// //                 />
// //                 <Flex
// //                   alignItems="center"
// //                 >
// //                   <Checkbox size="lg" textTransform="capitalize" color="dark" alignItems="center" colorScheme="purple">
// //                     remember my information
// //                   </Checkbox>
// //                 </Flex>
// //                 <Button
// //                   type="submit" height="45px" bg="primary"
// //                   color="light" textTransform="capitalize"
// //                   fontSize="1rem" border="1px solid transparent"
// //                   _hover={{ bg: "none", color: "primary", borderColor: "primary" }}
// //                   // isLoading={auth.isLoading}
// //                 >
// //                   Login
// //                 </Button>
// //                 <Flex
// //                   alignItems="center" color="dark" textTransform="capitalize"
// //                 >
// //                   forget your password?
// //                   <CLink to="/" color="primary" ms={2}>reset now</CLink>
// //                 </Flex>
// //               </Stack>
// //             </form>
// //           </Box>
// //         </SimpleGrid>
// //       </Container>
// //     </Flex >
// //   )
// // }

// // export default Login

// // import { useDispatch, useSelector } from "react-redux";
// import { useForm } from "react-hook-form";
// import { useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next";
// import {
//   Box,
//   Button,
//   Checkbox,
//   Container,
//   Flex,
//   Heading,
//   Stack,
//   Link as CLink,
//   Text,
//   Image,
// } from "@chakra-ui/react";
// import Niyat_logo from "../../../assets/images/logo.jpg";
// import Input from "../../../Components/form/Input";
// import { login } from "../services/authService";

// const Login = () => {
//   // const navigate = useNavigate();
//   // const dispatch = useDispatch();
//   // const auth = useSelector((state) => state.auth);

//   const {
//     handleSubmit,
//     formState: { errors },
//     control,
//   } = useForm();
//   const { t } = useTranslation();

//   const loginValidationSchema = {
//     email: {
//       required: t("validation.required"),
//     },
//     password: {
//       required: t("validation.required"),
//     },
//   };

//   const navigate = useNavigate()

//   return (
//     <Flex height="100vh" justifyContent="center" alignItems="center">
//       <Container
//         maxW="container.lg"
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//       >
//         <Box
//           columns={{ base: 1, md: 2 }}
//           width="50%"
//           bg="light"
//           boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
//           paddingInline={8}
//           paddingBlock={14}
//           borderRadius={6}
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//         >
//           {/* <Box flex="1" paddingInline={{ base: 2, md: 8 }} > */}
//           <form onSubmit={handleSubmit(values=>{
//              navigate("/designer")
//           })}

//           >
//             <Box display="flex" justifyContent="center" alignItems="center">
//               <Image src={Niyat_logo} alt="brand" width="120px" />
//             </Box>
//             <Heading
//               textAlign="center"
//               as="h2"
//               color="dark"
//               fontSize="1.5rem"
//               textTransform="capitalize"
//               mb={2}
//             >
//               Welcome Back
//             </Heading>
//             <Text
//               textAlign="center"
//               mb={6}
//               color="text"
//               _firstLetter={{ textTransform: "capitalize" }}
//               fontSize=".9rem"
//             >
//               Sign in and build some cool bots.
//             </Text>

//             <Stack spacing={4}>
//               <Input
//                 type="text"
//                 name="email"
//                 label="email"
//                 isRequired
//                 labelStyle={{ textTransform: "capitalize" }}
//                 placeholder="email@example.com"
//                 isFormController
//                 control={control}
//                 rules={loginValidationSchema.email}
//                 error={errors.email}
//               />
//               <Input
//                 type="password"
//                 name="password"
//                 label="password"
//                 isRequired
//                 labelStyle={{ textTransform: "capitalize" }}
//                 placeholder="password"
//                 isFormController
//                 control={control}
//                 rules={loginValidationSchema.password}
//                 error={errors.password}
//               />
//               <Flex alignItems="center">
//                 <Checkbox
//                   size="lg"
//                   textTransform="capitalize"
//                   color="dark"
//                   alignItems="center"
//                   colorScheme="blue"
//                 >
//                   remember my information
//                 </Checkbox>
//               </Flex>
//               <Button
//                 type="submit"
//                 height="45px"
//                 bg="blue.400"
//                 color="light"
//                 textTransform="capitalize"
//                 fontSize="1rem"
//                 border="1px solid transparent"
//                 _hover={{
//                   bg: "none",
//                   color: "blue.400",
//                   borderColor: "blue.400",
//                 }}
//                 // isLoading={auth.isLoading}
//               >
//                 Sign In
//               </Button>
//               <Flex alignItems="center" color="dark" textTransform="capitalize">
//                 forget your password?
//                 <CLink to="/" color="blue.400" ms={2}>
//                   reset now
//                 </CLink>
//               </Flex>
//             </Stack>
//           </form>
//           {/* </Box> */}
//         </Box>
//       </Container>
//     </Flex>
//   );
// };

// export default Login;

import React, { useState } from "react";
import "./Login.css";
import Niyat_logo from "../../../assets/images/logo.jpg";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const navigate = useNavigate();

  const navigatehandler = (e) => {
    e.preventDefault();
    if (email === "Admin@niyat.com" && password === "123") {
      navigate("/designer");
    } else {
      setErrorDiv(true);
    }
  };

  const [errorDiv, setErrorDiv] = useState(false);

  const [email, setEmail] = useState();
  const [password, setpassword] = useState();
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div class="form-container">
          <img src={Niyat_logo} alt="p" style={{ width: "100px" }}></img>
          <div class="words">
            <p class="title">Welcome back</p>
            <p>Sign in and build some cool bots</p>
          </div>
          <form class="form" onSubmit={navigatehandler}>
            <input
              type="email"
              class="input"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            ></input>
            <input
              type="password"
              class="input"
              placeholder="Password"
              onChange={(e) => setpassword(e.target.value)}
            ></input>
            <button
              class="form-btn"
              style={{ backgroundColor: "#4299E1" }}
              // onClick={navigatehandler}
              type="submit"
            >
              Log in
            </button>
          </form>
          {errorDiv && (
            <div style={{ color: "red" }}> email or password is wrong</div>
          )}
        </div>
      </div>
    </>
  );
};

export default Login;
