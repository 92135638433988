import React from "react";
import { Button } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import "./deleteButton.css";

const DeleteButton = ({ func, text }) => {
  return (
    <Button
      variant="contained"
      onClick={func}
      color="error"
      startIcon={<DeleteOutlinedIcon />}
      className="remove-field"
      sx={{ minWidth: "80px" }}
    >
      {text}
    </Button>
  );
};

export default DeleteButton;
