export default function Icon({type,setColor}) {
    switch (type) {
      case "Start":
        setColor("red");
        return (
          <svg width={40} height={40}>
            <circle className="iconCircle" cx={20} cy={20} r={20}></circle>
            <g>
              <path
                stroke="red"
                strokeWidth={3}
                className="icon"
                d="m29.4 17.1-17-7.1a.982.982 0 0 0-1.3 1.3l7.1 17a.961.961 0 0 0 .9.6.961.961 0 0 0 .9-.7l2-5.9 5.3 5.3a.967.967 0 0 0 1.4 0 .967.967 0 0 0 0-1.4L23.4 21l5.9-2a.961.961 0 0 0 .7-.9 1.326 1.326 0 0 0-.6-1Zm-8.1 2.5a.9.9 0 0 0-.6.6L19 25.1l-5.1-12.3L26.1 18Z"
              ></path>
            </g>
          </svg>
        );
      case "Send message":
        setColor("#2ab9cd");
        return (
          <svg
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
            height="40"
            width="40"
          >
            <g data-name="Group 4344">
              <circle
                data-name="Ellipse 709"
                cx="20"
                cy="20"
                r="20"
                style={{ fill: "rgb(228, 250, 251)" }}
              ></circle>
            </g>
            <g data-name="Group 4345">
              <path
                data-name="Path 1595"
                d="M13 10h14a2.006 2.006 0 0 1 2 2v10a2.006 2.006 0 0 1-2 2H16.1L11 28V12a2.006 2.006 0 0 1 2-2Zm7 18h8"
                style={{ fill: "rgb(228, 250, 251)" }}
              ></path>
              <path
                data-name="Path 1596"
                d="M11 29a.6.6 0 0 1-.4-.1.961.961 0 0 1-.6-.9V12a2.946 2.946 0 0 1 3-3h14a2.946 2.946 0 0 1 3 3v10a2.946 2.946 0 0 1-3 3H16.5l-4.8 3.8a2.544 2.544 0 0 1-.7.2Zm2-18a.945.945 0 0 0-1 1v14l3.5-2.7a1.421 1.421 0 0 1 .6-.2H27a.945.945 0 0 0 1-1V12a.945.945 0 0 0-1-1Zm15 18h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Z"
                style={{ fill: "#2ab9cd" }}
              ></path>
            </g>
          </svg>
        );
      case "Collect Input":
        setColor("rgb(53, 156, 236)");
        return (
          <svg
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
            height="40"
            width="40"
            style={{ fill: "#359cec" }}
          >
            <circle
              id="Collect_Input_svg__Ellipse_699"
              data-name="Ellipse 699"
              cx="20"
              cy="20"
              r="20"
              style={{ fill: "rgb(231, 245, 254)" }}
            ></circle>
            <g id="Collect_Input_svg__Group_4311" data-name="Group 4311">
              <g id="Collect_Input_svg__Group_4308" data-name="Group 4308">
                <path
                  id="Collect_Input_svg__Path_1565"
                  data-name="Path 1565"
                  className="Collect_Input_bg_svg__cls2"
                  d="M29 25h-7a1 1 0 0 1 0-2h6v-6h-6a1 1 0 0 1 0-2h7a.945.945 0 0 1 1 1v8a.945.945 0 0 1-1 1Zm-15 0h-3a.945.945 0 0 1-1-1v-8a.945.945 0 0 1 1-1h3a1 1 0 0 1 0 2h-2v6h2a1 1 0 0 1 0 2Z"
                ></path>
              </g>
              <g id="Collect_Input_svg__Group_4310" data-name="Group 4310">
                <g id="Collect_Input_svg__Group_4309" data-name="Group 4309">
                  <path
                    id="Collect_Input_svg__Path_1566"
                    data-name="Path 1566"
                    d="M18.5 13v14M16 13h5m-5 14h5"
                    style={{ fill: "rgb(255, 255, 255)", fillRule: "evenodd" }}
                  ></path>
                  <path
                    id="Collect_Input_svg__Path_1567"
                    data-name="Path 1567"
                    className="Collect_Input_bg_svg__cls2"
                    d="M21 28h-5a1 1 0 0 1 0-2h1.5V14H16a1 1 0 0 1 0-2h5a1 1 0 0 1 0 2h-1.5v12H21a1 1 0 0 1 0 2Z"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        );
      case "Buttons":
        setColor("#f5be4f");
        return (
          <svg
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
            height="40"
            width="40"
          >
            <g data-name="Group 4259" transform="translate(0 1)">
              <circle
                data-name="Ellipse 688"
                cx="20"
                cy="20"
                r="20"
                transform="translate(0 -1)"
                style={{ fill: "rgb(255, 246, 217)" }}
              ></circle>
            </g>
            <g data-name="Group 4260">
              <path
                data-name="Path 1515"
                d="M30 29H10a.945.945 0 0 1-1-1v-5a.945.945 0 0 1 1-1h20a.945.945 0 0 1 1 1v5a.945.945 0 0 1-1 1Zm-19-2h18v-3H11Zm19-8H10a.945.945 0 0 1-1-1v-5a.945.945 0 0 1 1-1h20a.945.945 0 0 1 1 1v5a.945.945 0 0 1-1 1Zm-19-2h18v-3H11Z"
                transform="translate(0 1)"
                style={{ fill: "#f5be4f" }}
              ></path>
            </g>
          </svg>
        );
      case "Carousel":
        setColor("#f5be4f");
        return (
          <svg
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
            height="40"
            width="40"
          >
            <g data-name="Group 4261">
              <circle
                data-name="Ellipse 689"
                cx="20"
                cy="20"
                r="20"
                style={{ fill: "rgb(255, 246, 217)" }}
              ></circle>
            </g>
            <g data-name="Group 4262">
              <path
                data-name="Path 1516"
                d="M8 8h24v24H8Z"
                style={{ fill: "none" }}
              ></path>
            </g>
            <g data-name="Group 4263">
              <path
                data-name="Path 1517"
                d="M34 26h-5a1 1 0 0 1 0-2h5a1 1 0 0 1 0 2Zm-9 0H15a1 1 0 0 1 0-2h10a1 1 0 0 1 0 2Zm-14 0H6a1 1 0 0 1 0-2h5a1 1 0 0 1 0 2Zm23-5h-5a.945.945 0 0 1-1-1v-8a.945.945 0 0 1 1-1h5a1 1 0 0 1 0 2h-4v6h4a1 1 0 0 1 0 2Zm-9 0H15a.945.945 0 0 1-1-1v-8a.945.945 0 0 1 1-1h10a.945.945 0 0 1 1 1v8a.945.945 0 0 1-1 1Zm-9-2h8v-6h-8Zm-5 2H6a1 1 0 0 1 0-2h4v-6H6a1 1 0 0 1 0-2h5a.945.945 0 0 1 1 1v8a.945.945 0 0 1-1 1Z"
                style={{ fill: "#f5be4f" }}
              ></path>
            </g>
          </svg>
        );
      case "Calender":
        setColor("#359cec");
        return (
          <svg
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
            height="40"
            width="40"
            style={{ fill: "#359cec" }}
          >
            <g id="Calender_svg__Group_4302" data-name="Group 4302">
              <circle
                id="Calender_svg__Ellipse_698"
                data-name="Ellipse 698"
                cx="20"
                cy="20"
                r="20"
                style={{ fill: "rgb(231, 245, 254)" }}
              ></circle>
            </g>
            <g
              id="Calender_svg__Group_4305"
              data-name="Group 4305"
              transform="translate(11 10)"
            >
              <g id="Calender_svg__Group_4303" data-name="Group 4303">
                <path
                  id="Calender_svg__Path_1561"
                  data-name="Path 1561"
                  className="Calender_bg_svg__cls-2"
                  d="M16 21H2a2.946 2.946 0 0 1-3-3V4a2.946 2.946 0 0 1 3-3h14a2.946 2.946 0 0 1 3 3v14a2.946 2.946 0 0 1-3 3ZM2 3a.945.945 0 0 0-1 1v14a.945.945 0 0 0 1 1h14a.945.945 0 0 0 1-1V4a.945.945 0 0 0-1-1Z"
                ></path>
              </g>
              <g id="Calender_svg__Group_4304" data-name="Group 4304">
                <path
                  id="Calender_svg__Path_1562"
                  data-name="Path 1562"
                  className="Calender_bg_svg__cls-2"
                  d="M18 9H0a.945.945 0 0 1-1-1 .945.945 0 0 1 1-1h18a.945.945 0 0 1 1 1 .945.945 0 0 1-1 1Zm-5-4a.945.945 0 0 1-1-1V0a.945.945 0 0 1 1-1 .945.945 0 0 1 1 1v4a.945.945 0 0 1-1 1ZM5 5a.945.945 0 0 1-1-1V0a.945.945 0 0 1 1-1 .945.945 0 0 1 1 1v4a.945.945 0 0 1-1 1Z"
                ></path>
              </g>
            </g>
          </svg>
        );
      case "Send an email":
        setColor("#cb62e4");
        return (
          <svg
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
            height="40"
            width="40"
          >
            <circle
              data-name="Ellipse 696"
              cx="20"
              cy="20"
              r="20"
              style={{ fill: "rgb(248, 240, 252)" }}
            ></circle>
            <g data-name="Group 4297">
              <path
                data-name="Path 1555"
                d="m30 14-7.7 5.4a3.9 3.9 0 0 1-4.6 0L10 14v-1a2.006 2.006 0 0 1 2-2h16a2.006 2.006 0 0 1 2 2Z"
                style={{ fillRule: "evenodd", fill: "rgb(248, 240, 252)" }}
              ></path>
              <path
                data-name="Path 1556"
                d="M28 10H12a2.946 2.946 0 0 0-3 3v12a2.946 2.946 0 0 0 3 3h16a2.946 2.946 0 0 0 3-3V13a2.946 2.946 0 0 0-3-3Zm-17 3a.945.945 0 0 1 1-1h16a.945.945 0 0 1 1 1v.5l-7.3 5.1a3.015 3.015 0 0 1-3.4 0L11 13.5Zm17 13H12a.945.945 0 0 1-1-1v-9.1l6.1 4.3a5.122 5.122 0 0 0 5.8 0l6.1-4.3V25a.945.945 0 0 1-1 1Z"
                style={{ fill: "#cb62e4" }}
              ></path>
            </g>
          </svg>
        );
      case "Branch":
        setColor("#f5be4f");
        return (
          <svg
            viewBox="0 0 40 39.8"
            xmlns="http://www.w3.org/2000/svg"
            height="40"
            width="40"
          >
            <g data-name="Group 4257">
              <ellipse
                data-name="Ellipse 685"
                cx="20"
                cy="19.9"
                rx="20"
                ry="19.9"
                style={{ fill: "rgb(255, 246, 217)" }}
              ></ellipse>
            </g>
            <path
              data-name="Path 1513"
              d="M28 23.1V23a10.968 10.968 0 0 0-11-11h-1v-2a1 1 0 0 0-2 0v13.1a4.012 4.012 0 1 0 5 3.9 4.079 4.079 0 0 0-3-3.9V14h1a8.963 8.963 0 0 1 9 9v.1a4.012 4.012 0 1 0 5 3.9 4.079 4.079 0 0 0-3-3.9ZM17 27a2 2 0 1 1-2-2 2.006 2.006 0 0 1 2 2Zm10 2a2 2 0 1 1 2-2 2.006 2.006 0 0 1-2 2Z"
              style={{ fill: "#f5be4f" }}
            ></path>
          </svg>
        );
      case "Image Carousel":
        setColor("#2ab9cd");
        return (
          <svg
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
            height="40"
            width="40"
            style={{ fill: "#2ab9cd" }}
          >
            <g id="Image_Carousel_svg_bg__Oval">
              <circle
                id="Image_Carousel_svg_bg__Ellipse_795"
                cx="20"
                cy="20"
                r="20"
                style={{ fill: "rgb(228, 250, 251)" }}
              ></circle>
            </g>
            <g id="Image_Carousel_svg_bg__path" transform="translate(5 8)">
              <g id="Image_Carousel_svg_bg__Rectangle" transform="translate(3)">
                <path
                  id="Image_Carousel_svg_bg__Rectangle_2078"
                  d="M0 0h24v24H0z"
                  style={{ fill: "none" }}
                ></path>
              </g>
              <g id="Image_Carousel_svg_bg__R_1" transform="translate(9 7)">
                <path
                  id="Image_Carousel_svg_bg__Path_3012"
                  className="Image_Carousel_svg_bg__cls-3"
                  d="M11.018 10h-10A1.034 1.034 0 0 1 0 9.018v-8A.994.994 0 0 1 1.018 0h10A.978.978 0 0 1 12 .982v8A.986.986 0 0 1 11.018 10ZM2 8h8V2H2Z"
                ></path>
              </g>
              <g id="Image_Carousel_svg_bg__Path-2" transform="translate(0 7)">
                <path
                  id="Image_Carousel_svg_bg__Path_3013"
                  className="Image_Carousel_svg_bg__cls-3"
                  d="M6 10H1.018A1.016 1.016 0 0 1 0 8.982.965.965 0 0 1 1.018 8h4V2h-4A.986.986 0 0 1 0 1.018 1.016 1.016 0 0 1 1.018 0h5.018a1.04 1.04 0 0 1 1.019 1.018v8A1.067 1.067 0 0 1 6 10Z"
                ></path>
              </g>
              <g id="Image_Carousel_svg_bg__Path-3" transform="translate(23 7)">
                <path
                  id="Image_Carousel_svg_bg__Path_3014"
                  className="Image_Carousel_svg_bg__cls-3"
                  d="M6 10H1.018A1.034 1.034 0 0 1 0 9.018v-8A.986.986 0 0 1 .982 0H6a1.016 1.016 0 0 1 1.018 1.018A.986.986 0 0 1 6 2H2v6h4a1.016 1.016 0 0 1 1.018 1.018A.986.986 0 0 1 6 10Z"
                ></path>
              </g>
            </g>
          </svg>
        );
      case "Slider":
        setColor("#359cec");
        return (
          <svg
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
            height="40"
            width="40"
            style={{ fill: "#359cec" }}
          >
            <g id="Slider_bg_svg__icon_collect_input">
              <g
                id="Slider_bg_svg__Group_5235"
                transform="translate(14.028 13.099)"
              >
                <circle
                  id="Slider_bg_svg__Ellipse_784"
                  cx="0.909"
                  cy="0.909"
                  r="0.909"
                  transform="translate(1.164 1.055)"
                  style={{ fill: "none" }}
                ></circle>
                <path
                  id="Slider_bg_svg__Path_2973"
                  d="M1.964 0a1.964 1.964 0 1 0 1.963 1.964A1.955 1.955 0 0 0 1.964 0Zm0 2.945a.982.982 0 1 1 .982-.982.978.978 0 0 1-.982.982Z"
                  style={{ fill: "rgb(132, 94, 247)" }}
                ></path>
              </g>
              <g id="Slider_bg_svg__Group_5496">
                <g id="Slider_bg_svg__Oval">
                  <g id="Slider_bg_svg__Group_5234">
                    <circle
                      id="Slider_bg_svg__Ellipse_783"
                      cx="20"
                      cy="20"
                      r="20"
                      style={{ fill: "rgb(231, 245, 254)" }}
                    ></circle>
                  </g>
                </g>
                <g
                  id="Slider_bg_svg__Group_5495"
                  transform="translate(9.455 12.109)"
                >
                  <path
                    id="Slider_bg_svg__Union_7"
                    className="Slider_bg_svg__cls-4"
                    d="M42.918 46.355a2.945 2.945 0 1 1 2.946 2.945 2.98 2.98 0 0 1-2.946-2.945ZM35.9 36.245a2.945 2.945 0 1 1 2.945 2.945 2.933 2.933 0 0 1-2.945-2.945Z"
                    transform="translate(-32.3 -33.3)"
                  ></path>
                  <path
                    id="Slider_bg_svg__Rectangle_2176"
                    className="Slider_bg_svg__cls-4"
                    d="M26.909 39h3.273a.9.9 0 0 1 .909.909.9.9 0 0 1-.909.909h-3.273a.9.9 0 0 1-.909-.909.9.9 0 0 1 .909-.909Z"
                    transform="translate(-26 -36.927)"
                  ></path>
                  <path
                    id="Slider_bg_svg__Rectangle_2177"
                    className="Slider_bg_svg__cls-4"
                    d="M55.909 39h6.545a.9.9 0 0 1 .909.909.9.9 0 0 1-.909.909h-6.545a.9.9 0 0 1-.909-.909.9.9 0 0 1 .909-.909Z"
                    transform="translate(-44.455 -36.927)"
                  ></path>
                  <path
                    id="Slider_bg_svg__Rectangle_2178"
                    className="Slider_bg_svg__cls-4"
                    d="M68.182 68.818h-3.273a.9.9 0 0 1-.909-.909.9.9 0 0 1 .909-.909h3.273a.9.9 0 0 1 .909.909.9.9 0 0 1-.909.909Z"
                    transform="translate(-50.182 -54.745)"
                  ></path>
                  <path
                    id="Slider_bg_svg__Rectangle_2179"
                    className="Slider_bg_svg__cls-4"
                    d="M33.455 68.818h-6.546a.9.9 0 0 1-.909-.909.9.9 0 0 1 .909-.909h6.545a.9.9 0 0 1 .909.909.9.9 0 0 1-.908.909Z"
                    transform="translate(-26 -54.745)"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        );
    }
  }