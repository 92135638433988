import { useEffect, useState } from "react";
import "./styles/App.css";
import Activity from "./Components/Activity";
import Relation from "./Components/Relation";
import React from "react";
import Slide from "./Components/Slider2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function App() {
  const [render, setRerender] = useState(false);
  const [Child, setChild] = useState(false);

  function reRender() {
    setRerender(!render);
  }

  function addChild() {
    setChild(!Child);
  }

  const [dataPath, setDataPath] = useState([]);

  const [count, setCount] = useState(1);
  const [data, setData] = useState([
    {
      id: 0,
      text: "HI",
      description: "Welcome",
      type: "Start",
      x: 400,
      y: 0,
      x2: 400,
      y2: 0
    }
  ]);
  // const [mousePos, setMousePos] = useState({});

  function handleData(item) {
    setCount(count + 1);

    setData([...data, item]);
  }

  function handleDataPath(item) {
    setDataPath([...dataPath, item]);
  }

  const [index, setIndex] = useState(undefined);

  const [isOpen, setIsOpen] = useState(false);

  const [onButton, setOnButton] = useState(false);
  const [onActivity, setOnActivity] = useState(false);

  // Function to fetch 'id' and 'message' from externalData
  const fetchIdAndMessage = async () => {
    console.log("kol al data", data, dataPath);

    console.log("al data", JSON.stringify(data));
    console.log("al path", JSON.stringify(dataPath));

    const finalObject = {
      Task: data,
      PathTask: dataPath
    };

    console.log("a5ls", JSON.stringify(finalObject));

    const dynamicData = {
      AccountNumber: "80",
      body: finalObject
    };

    console.log("wala ya wala", JSON.stringify(dynamicData));

    const res = await fetch(
      `https://chatbotapi.mygatein.com/chatbot/KBot/FN1?iddata=${JSON.stringify(
        dynamicData
      )}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
    const data3 = await res.json();

    console.log("dataaaaa jo", data);

    console.log("al path", dataPath);

    toast.success("Data saved successfully!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light"
    });
  };

  const navigate = useNavigate();

  // const viewerNavigate=()=>{
  //   navigate("/Viewer")
  // }

  const viewerNavigate = () => {
    // const newTab = window.open("/InterviewChatbot", "_blank");
    // newTab.focus();
    navigate("/InterviewChatbot");
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        overflowY: "auto"
      }}
      //       onMouseUpCapture={() => {
      //         if (!onActivity) {
      // setOnButton(false)
      //           setDataPath(dataPath.splice(dataPath.length - 1, 1));
      //           reRender();
      //           console.log(dataPath);
      //           console.log(onActivity);
      //         }
      //       }}
    >
      <div style={{ height: 150, width: 300 }}>
        {data.map((items, index) => (
          <Activity
            key={index}
            handleData={item => handleData(item)}
            handleDataPath={item => handleDataPath(item)}
            x={items.x}
            y={items.y}
            data={data}
            count={count}
            index={index}
            type={items.type}
            pathData={dataPath}
            reRender={reRender}
            Child={Child}
            setIndex={item => setIndex(item)}
            setIsOpen={setIsOpen}
            setOnButton={setOnButton}
            setOnActivity={setOnActivity}
            onButton={onButton}
            // mousePos={mousePos}
          ></Activity>
        ))}
      </div>

      <svg
        className="pathSvg"
        style={{ overflow: "visible", position: "absolute", top: 0, left: 0 }}
      >
        {dataPath.map((_item, index) => (
          <Relation
            key={index}
            reRender={reRender}
            pathData={dataPath}
            index={index}
            data={data}
            // mousePos={mousePos}
          ></Relation>
        ))}
      </svg>

      <Slide
        reRender={reRender}
        data={data}
        pathData={dataPath}
        index={index}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        addChild={addChild}
      ></Slide>
      <div>
        <button className="viewBtn" onClick={viewerNavigate}>
          view
        </button>

        <button className="saveBtn" onClick={fetchIdAndMessage}>
          save
        </button>
        <ToastContainer />
      </div>
    </div>
  );
}

export default App;
