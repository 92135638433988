import React, { useState } from "react";
import BackGround from "../../../assets/bg.jpg";
import Chat from "../../../viewComponents/Chat/Chat";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { Container } from "@mui/material";
import Header from "../../../viewComponents/Layout/Header";
import Headline from "../../../viewComponents/Home/Headline";
//import "../../../viewComponents/Styles/chat.module.css";
import "./styles/InterviewChatbot.css";
import ChatSound from "../../../assets/audios/openChat.wav";
const Home = ({ direction, setDirection }) => {
  const chatSound = new Audio(ChatSound);
  const playChatSound = () => {
    chatSound.play();
  };
  const [showChatBox, setShowChatBox] = useState(false);
  const [dataFromChild, setDataFromChild] = useState(null);
  const [isAnimating, setIsAnimating] = useState(true);

  // Function to receive data from the child component
  const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };

  const fetchData = async () => {
    console.log("waryny kdh al data aly d5lha" , dataFromChild);
    try {
      await fetch(
        `https://chatbotapi.mygatein.com/chatbot/KBot/FN2?iddata=${JSON.stringify(
          dataFromChild
        )}`
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <section
      style={{
        backgroundImage: `url(${BackGround})`,
        position: "relative",
      }}
    >
      <Header direction={direction} setDirection={setDirection} />
      <Container>
        <Headline direction={direction} setDirection={setDirection} />
        {/* <Form direction={direction} data={dropData} /> */}
      </Container>
      {showChatBox && (
        <div
          className="chatBot"
          style={{
            position: "fixed",
            right: "50px",
            float: "right",
            bottom: "85px",
            zIndex: "5000",
          }}
        >
          <Chat sendDataToParent={handleDataFromChild} />
        </div>
      )}
      <div
        direction={direction}
        className="chatBotIconContainer"
        onClick={() => {
          setShowChatBox((prev) => !prev);
          setIsAnimating((prev) => !prev); // Toggle the animation on click
          if (!showChatBox) {
            playChatSound(); // Play the sound when the chat box is shown
          }
        }}
      >
        <ChatBubbleIcon
          className={`chatBotIcon ${isAnimating ? 'bounce' : ''}`}
          style={{
            fontSize: "50px",
            color: "#6345C7",
            position: "absolute",
            top: "10px",
            left: "-4px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        direction={direction}
        className="chatBotSaveDate"
        onClick={() => fetchData()}
      >
        Save
      </div>
    </section>
  );
};

export default Home;
